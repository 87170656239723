'use strict';
(function() {

  class CartComponent {
    constructor(Auth, Util, usSpinnerService, Cart, Modal, WOPackage, toastr, $stateParams, $state, $rootScope) {
      this.Cart = Cart;
      this.WOPackage = WOPackage;
      this.Modal = Modal;
      this.toastr = toastr;
      this.$stateParams = $stateParams;
      this.$rootScope = $rootScope;
      this.usSpinnerService = usSpinnerService;
      this.isLoggedIn = Auth.isLoggedIn;
      this.isOrganizationUserPlus = Auth.isOrganizationUserPlus;
      this.isOrganizationAdmin = Auth.isOrganizationAdmin;
      this.selectedCategory = 'all';
      this.Auth = Auth;
      this.getClass = Util.getFindingClass;
      this.allFindings = [];
      this.Auth.isLoggedInAsync((loggedIn)=> {
        if (loggedIn) {
          this.init();
        } else {
          $state.go('login');
        }
      });
    }

    loadCart() {
      this.startSpin('spinner-1');
      this.Cart.getByOrganization({ controller: this.Auth.getCurrentUser().organization._id }).$promise
        .then(response=>{
          this.cart = response.data;
          this.allFindings = response.data.findings.slice();
          this.findings = this.allFindings;
          this.$rootScope.$emit('updateCart', response.data.findings.length);
          this.stopSpin('spinner-1');
        })
        .catch(err=>{
          this.stopSpin('spinner-1');
        });
    }

    init() {
      this.loadCart();
      this.confirmDelete = this.Modal.confirm.delete(finding=>{
        this.startSpin('spinner-1');
        this.Cart.removeFinding({ controller: this.Auth.getCurrentUser().organization._id, page: finding._id }).$promise
          .then(()=>{
            this.loadCart();
            this.toastr.success('Finding removed from cart');
            this.stopSpin('spinner-1');
          })
          .catch(err=>{
            if(err.data.meta && err.data.meta.error_message) {
              this.toastr.error(err.data.meta.error_message);
            } else {
              this.toastr.error('Something went wrong. please try again after reloading.');
            }
            this.stopSpin('spinner-1');
          })
      });
    }

    filterResults() {
      if (this.selectedCategory != 'all') {
        this.findings = this.allFindings.filter(finding=>finding.category >= this.selectedCategory);
      } else {
        this.findings = this.allFindings.slice();
      }
    }

    requestQuote() {
      this.WOPackage.create({}, { findings: _.map(this.cart.findings, (finding)=> finding._id) }).$promise
      .then(response=>{
        this.toastr.success('Workorder package created');
        this.loadCart();
      })
      .catch(err=>{
        if(err.data.meta && err.data.meta.error_message) {
          this.toastr.error(err.data.meta.error_message);
        } else {
          this.toastr.error('Something went wrong. please try again after reloading.');
        }
      })
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }
  }

  angular.module('windmanagerApp')
    .component('cart', {
      templateUrl: 'app/cart/cart.html',
      controller: CartComponent,
      controllerAs: 'cc'
    });

})();
